<template>
  <q-banner :class="bannerClass">
    <slot />
  </q-banner>
</template>

<script>
export default {
  name: 'Banner',
  props: {
    variant: {
      type: String,
      default: '',
      validator: val => {
        if (val) {
          return [
            'default',
            'primary',
            'secondary',
            'success',
            'warning',
            'info',
            'danger'
          ].includes(val)
        }
        return true
      }
    }
  },
  setup(props) {
    const bannerClass = [
      'q-px-lg text-white',
      {
        'bg-white text-primary font-semibold': !props.variant,
        'bg-gray text-black': props.variant === 'default',
        'bg-primary': props.variant === 'primary',
        'bg-secondary': props.variant === 'secondary',
        'bg-success': props.variant === 'success',
        'bg-warning': props.variant === 'warning',
        'bg-info': props.variant === 'info',
        'bg-danger': props.variant === 'danger'
      }
    ]

    return { bannerClass }
  }
}
</script>
