<template>
  <div class="row items-center justify-between">
    <div>
      <PageHeader
        breadcrumb="Campaign"
        :title="`${isUpdatePage ? 'Update' : 'Create'} Campaign`"
      />
    </div>

    <div class="q-gutter-md">
      <Button
        variant="primary"
        icon="arrow_back"
        size="lg"
        outline
        rounded
        @click="goToListsPage"
      >
        <Tooltip text="Go back to lists" />
      </Button>
      <Button
        variant="primary"
        label="Save Draft"
        size="lg"
        outline
        :loading="isLoading"
        :disabled="isLoading"
        @click="onSubmitCreate('draft')"
        @mousedown="toggleDraft"
        v-if="!isUpdatePage"
      >
        <template v-slot:icon>
          <q-icon name="drafts" size="md" />
        </template>
      </Button>
      <Button
        variant="primary"
        label="Submit Request"
        size="lg"
        outline
        :loading="isLoading"
        :disabled="isLoading"
        @click="onSubmitCreate('requested')"
        @mousedown="toggleCreate"
        v-if="!isUpdatePage && campaignStep === campaignStepEnd"
      >
        <template v-slot:icon>
          <q-icon name="file_upload" size="md" />
        </template>
      </Button>
      <Button
        variant="primary"
        label="Save"
        size="lg"
        outline
        :loading="isLoading"
        :disabled="isLoading"
        @click="onSubmitCreate"
        @mousedown="toggleSave"
        v-if="isUpdatePage && isUpdateAllowed"
      >
        <template v-slot:icon>
          <q-icon name="save" size="md" />
        </template>
      </Button>
      <Button
        variant="primary"
        label="Help"
        size="lg"
        outline
        @click="onToggleModal"
      >
        <template v-slot:icon>
          <img :src="getIconUrl('icon_how_to')" width="30" />
        </template>
      </Button>
    </div>
  </div>

  <PageContent>
    <Loader :loading="loadingLists" v-if="loadingLists" />

    <Notif
      variant="danger"
      message="You are not allowed to update this campaign"
      :visible="isUpdatePage && !isUpdateAllowed"
      noClose
      v-else-if="isUpdatePage && !isUpdateAllowed"
    />
    <q-form ref="filterForm" v-else>
      <q-stepper
        v-model="campaignStep"
        ref="stepper"
        color="primary"
        animated
        alternative-labels
      >
        <q-step
          class="stepHeight"
          :name="1"
          title="Set Campaign Details"
          icon="settings"
          :done="campaignStep > 1"
        >
          <CampaignFormContainer>
            <div class="row items-start q-col-gutter-sm">
              <div class="col-xs-12 col-sm-3 col-md-3 labelContainer">
                <label class="labelText"> * Campaign Code </label>
              </div>
              <div class="col-xs-12 col-sm-9 col-md-9">
                <FormInput
                  v-model="campaignCode"
                  label="Campaign Code"
                  maxlength="20"
                  :rules="[VALIDATION.required]"
                  tabindex="1"
                  outlined
                  counter
                />
              </div>
            </div>

            <div class="row items-start q-col-gutter-sm">
              <div class="col-xs-12 col-sm-3 col-md-3 labelContainer">
                <label class="labelText"> * Campaign Name </label>
              </div>
              <div class="col-xs-12 col-sm-9 col-md-9">
                <FormInput
                  v-model="campaignName"
                  label="Campaign Name"
                  :rules="[VALIDATION.required]"
                  tabindex="2"
                  outlined
                />
              </div>
            </div>

            <div class="row items-start q-col-gutter-sm">
              <div class="col-xs-12 col-sm-3 col-md-3 labelContainer">
                <label class="labelText"> * Campaign Duration </label>
              </div>
              <div class="col-xs-12 col-sm-9 col-md-9">
                <div class="row items-start q-col-gutter-sm">
                  <div class="col-xs-12 col-sm-6 col-md-6">
                    <FormDate
                      label="Date From"
                      mask="date"
                      v-model="campaignDateFrom"
                      :value="campaignDateFrom"
                      :rules="requiredField"
                      @input="onSelectFromDate"
                      tabindex="3"
                    />
                  </div>

                  <div class="col-xs-12 col-sm-6 col-md-6">
                    <FormDate
                      label="Date To"
                      mask="date"
                      v-model="campaignDateTo"
                      :value="campaignDateTo"
                      :disabled="!campaignDateFrom"
                      :options="date => date >= minDate"
                      :rules="requiredField"
                      @input="onSelectToDate"
                      tabindex="4"
                    />
                  </div>
                </div>
              </div>
            </div>
          </CampaignFormContainer>
        </q-step>

        <q-step
          class="stepHeight"
          :name="2"
          title="Add Mechanics and Attachment"
          icon="assignment"
          :done="campaignStep > 2"
        >
          <CampaignFormContainer>
            <div class="row items-start q-col-gutter-sm">
              <div class="col-xs-12 col-sm-3 col-md-3 labelContainer">
                <label class="labelText"> * Mechanics </label>
              </div>
              <div class="col-xs-12 col-sm-9 col-md-9">
                <FormInput
                  v-model="campaignMechanics"
                  type="textarea"
                  label="Mechanics"
                  maxlength="2000"
                  :rules="requiredField"
                  tabindex="5"
                  outlined
                  counter
                  autogrow
                />
              </div>

              <div class="col-xs-12 col-sm-3 col-md-3" />
              <div class="col-xs-12 col-sm-9 col-md-9">
                <FormFile
                  v-model="campaignFile"
                  label="Attachment"
                  hint="Accepts excel, doc, and pdf files only."
                  :accept="allowedFileTypes.toString()"
                  :rules="requiredField"
                  :max-files="maxFileCount"
                  :max-file-size="maxFileSize"
                  :filter="checkFileType"
                  :outlined="false"
                  @onFileError="onFileError"
                  @onFileUpdate="onUpload"
                  use-chips
                  tabindex="6"
                />
              </div>
            </div>
          </CampaignFormContainer>
        </q-step>

        <q-step
          class="stepHeight"
          :name="3"
          title="Select Customer Segment"
          icon="groups"
          :done="campaignStep > 3"
        >
          <CampaignFormContainer>
            <div class="row items-start q-col-gutter-sm">
              <div class="col-xs-12 col-sm-3 col-md-3 labelContainer">
                <label class="labelText"> * Retailer </label>
              </div>
              <div class="col-xs-12 col-sm-9 col-md-9">
                <SelectRetailers
                  v-model="campaignRetailer"
                  :values="campaignRetailer"
                  :rules="requiredField"
                  :multiple="false"
                  @updateStore="$event => (campaignRetailer = $event)"
                  tabindex="7"
                />
              </div>
            </div>

            <div class="row items-start q-col-gutter-sm mt-4">
              <div class="col-xs-12 col-sm-3 col-md-3 labelContainer">
                <label class="labelText"> </label>
              </div>

              <div class="column q-gutter-sm">
                <q-radio
                  v-model="campaignSegmentType"
                  val="all"
                  label="All Customers"
                />
                <q-radio
                  v-model="campaignSegmentType"
                  val="segment"
                  label="By Customer Segment"
                />
              </div>
            </div>

            <div class="row items-start q-col-gutter-sm">
              <div class="col-xs-12 col-sm-3 col-md-3 labelContainer">
                <label class="labelText"> </label>
              </div>
              <div
                class="col-xs-12 col-sm-9 col-md-9"
                v-if="campaignSegmentType === 'segment'"
              >
                <SelectSegments
                  v-model="campaignSegment"
                  max-values="5"
                  hint="Max of 5 segments"
                  option-value="value"
                  option-label="label"
                  :values="campaignSegment"
                  :retailer="campaignRetailer"
                  :rules="requiredFieldArray"
                  :multiple="true"
                  :disabled="!campaignRetailer"
                  :disabledSelectAll="true"
                  counter
                  use-chips
                  withRetailer
                  rawData
                  tabindex="8"
                />
              </div>
            </div>
          </CampaignFormContainer>
        </q-step>

        <q-step
          class="stepHeight"
          :name="4"
          title="Set Campaign Type"
          icon="add_comment"
          :done="campaignStep > 4"
        >
          <CampaignFormContainer>
            <div class="row items-start q-col-gutter-sm">
              <div class="col-xs-12 col-sm-3 col-md-3 labelContainer">
                <label class="labelText"> * Campaign Type </label>
              </div>
              <div class="col-xs-12 col-sm-9 col-md-9">
                <SelectCampaignType
                  v-model="campaignDiscountType"
                  option-value="value"
                  option-label="label"
                  :values="campaignDiscountType"
                  :rules="requiredField"
                  @updateStore="$event => (campaignDiscountType = $event)"
                  rawData
                  tabindex="9"
                />
              </div>
            </div>
          </CampaignFormContainer>
        </q-step>

        <q-step
          class="stepHeight"
          :name="5"
          title="Send Notification"
          icon="add_comment"
          :done="campaignStep > 5"
        >
          <CampaignFormContainer>
            <div class="row items-start q-col-gutter-sm">
              <div class="col-xs-12 col-sm-3 col-md-3 labelContainer">
                <label class="labelText"> * Notification Message </label>
              </div>
              <div class="col-xs-12 col-sm-9 col-md-9">
                <FormInput
                  v-model="campaignMessage"
                  type="textarea"
                  label="Notification Message"
                  maxlength="2000"
                  :rules="requiredField"
                  tabindex="10"
                  outlined
                  counter
                  autogrow
                />
              </div>
            </div>
          </CampaignFormContainer>
        </q-step>

        <q-step
          class="stepHeight"
          :name="6"
          title="Summary"
          icon="summarize"
          :done="campaignStep > 6"
        >
          <q-list bordered separator style="min-width: 600px">
            <q-item>
              <q-item-section class="listHeader">Campaign Code</q-item-section>
              <q-item-section>{{ campaignCode }}</q-item-section>
            </q-item>

            <q-item>
              <q-item-section class="listHeader">Campaign Name</q-item-section>
              <q-item-section>{{ campaignName }}</q-item-section>
            </q-item>

            <q-item>
              <q-item-section class="listHeader">
                Campaign Duration
              </q-item-section>
              <q-item-section>{{ campaignName }}</q-item-section>
            </q-item>

            <q-item>
              <q-item-section class="listHeader">
                Campaign Mechanics
              </q-item-section>
              <q-item-section>
                <q-item-label>{{ campaignMechanics }}</q-item-label>
                <q-item-label caption v-if="campaignFile">
                  <q-icon name="attach_file" size="xs" />
                  {{ campaignFile[0]?.name ?? campaignFile?.name }}
                </q-item-label>
              </q-item-section>
            </q-item>

            <q-item>
              <q-item-section class="listHeader">
                Campaign Segment
              </q-item-section>
              <q-item-section>
                <q-item-label v-if="campaignSegmentType === 'segment'">
                  <Badge
                    class="q-mr-sm"
                    v-for="segment in campaignSegment"
                    variant="default"
                    :key="segment.value"
                    :text="segment.label"
                  />
                </q-item-label>
                <q-item-label v-else> All Customers </q-item-label>
              </q-item-section>
            </q-item>

            <q-item>
              <q-item-section class="listHeader">
                Campaign Type
              </q-item-section>
              <q-item-section>
                <q-item-label>
                  {{ campaignDiscountType?.label }}
                </q-item-label>
              </q-item-section>
            </q-item>

            <q-item>
              <q-item-section class="listHeader">Notification</q-item-section>
              <q-item-section>
                <q-item-label caption>
                  {{ campaignMessage }}
                </q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-step>

        <template v-slot:navigation>
          <CampaignStepperButton
            :step="campaignStep"
            :stepEnd="campaignStepEnd"
            :loading="isLoading"
            @next="onStepNext()"
            @previous="onStepPrevious()"
            @skip="onStepSkip()"
            @mouseDown="toggleCreate()"
          />
        </template>

        <template v-slot:message>
          <CampaignStepperBanner
            text="Create a campaign by giving it a code and name."
            v-if="campaignStep === 1"
          >
            <div class="flex items-center gap-2">
              <q-icon name="info" size="xs" />
              Set the duration of the campaign.
            </div>
          </CampaignStepperBanner>
          <CampaignStepperBanner
            text="Input the mechanics for your campaign - be as descriptive as
              possible."
            v-else-if="campaignStep === 2"
          >
            <div class="flex items-center gap-2">
              <q-icon name="info" size="xs" />
              Attach a supporting file - usually the retailer-approved Trade
              Letter.
            </div>
          </CampaignStepperBanner>
          <CampaignStepperBanner
            text="Select customer segments to include in the campaign. (Max of 5)"
            v-else-if="campaignStep === 3"
          />
          <CampaignStepperBanner
            text="Set your campaign type."
            v-else-if="campaignStep === 4"
          />
          <CampaignStepperBanner
            text="Choose if you want to send an in-app notification."
            v-else-if="campaignStep === 5"
          />
          <CampaignStepperBanner
            text="Click on Submit Request to finalize this campaign."
            v-else-if="campaignStep === 6"
          />
        </template>
      </q-stepper>
    </q-form>
  </PageContent>

  <Modal
    :show="showModal"
    :footer="false"
    header="Help"
    width="700px"
    cancelLabel="Close"
    @close="onToggleModal"
    closeOnly
  >
    <div class="p-4">
      <h5 class="q-mb-md">Create Voucher Campaign</h5>
      <p>
        {{ HELP.CAMPAIGN_CREATE.description }}
      </p>

      <q-separator class="q-my-md" />

      <div class="q-mb-md">
        <h6 class="text-sm mb-2">
          {{ HELP.CAMPAIGN_CREATE.create.description }}
        </h6>
        <ol>
          <li v-for="list in HELP.CAMPAIGN_CREATE.create.lists" :key="list.id">
            {{ list.text }}
          </li>
        </ol>
      </div>
    </div>
  </Modal>
</template>

<script>
import { ref, inject, onBeforeMount, watch, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useQuasar } from 'quasar'
import { omitBy, isNil } from 'lodash'

import PageHeader from '@/components/PageHeader'
import PageContent from '@/components/PageContent'
import Button from '@/components/Button'
import { FormInput, FormDate, FormFile } from '@/components/inputs'
import Modal from '@/components/Modal'
import Loader from '@/components/Loader'
import Tooltip from '@/components/Tooltip'
import Badge from '@/components/Badge'
import Notif from '@/components/Notif'

import {
  SelectSegments,
  SelectRetailers,
  SelectCampaignType
} from '@/components/customs'
import CampaignFormContainer from './components/CampaignFormContainer'
import CampaignStepperBanner from './components/CampaignStepperBanner'
import CampaignStepperButton from './components/CampaignStepperButton'

import { HELP, campaign } from '@/constants'
import { FORMAT, VALIDATION, DATE, getIconUrl, Toast, fetchData } from '@/tools'

export default {
  name: 'CampaignCreate',
  components: {
    PageHeader,
    PageContent,
    Button,
    FormInput,
    FormDate,
    FormFile,
    Modal,
    Loader,
    Tooltip,
    Badge,
    Notif,
    SelectSegments,
    SelectRetailers,
    SelectCampaignType,
    CampaignFormContainer,
    CampaignStepperBanner,
    CampaignStepperButton
  },
  setup() {
    const $q = useQuasar()
    const router = useRouter()
    const { showToast } = Toast()
    const state = inject('store')?.state
    const campaignID = router?.currentRoute?.value?.params?.id
    const isUpdatePage = router?.currentRoute?.value?.name === 'campaign_update'

    const {
      data: dataLists,
      error: errorLists,
      loading: loadingLists,
      get: getLists
    } = fetchData()

    const {
      data: dataCreate,
      error: errorCreate,
      loading: loadingCreate,
      post: postCreate
    } = fetchData()

    const {
      data: dataUpdate,
      error: errorUpdate,
      loading: loadingUpdate,
      put: postUpdate
    } = fetchData()

    const {
      data: dataUpload,
      error: errorUpload,
      loading: loadingUpload,
      upload: postUpload
    } = fetchData()

    const isLoading = computed(() => {
      return (
        loadingLists.value ||
        loadingCreate.value ||
        loadingUpload.value ||
        loadingUpdate.value
      )
    })

    const minDate = computed(() => {
      return DATE.toFriendlyDate(campaignDateFrom.value, '/')
    })

    const requiredField = computed(() => {
      return isDraft.value ? [] : [VALIDATION.required]
    })

    const requiredFieldArray = computed(() => {
      return [VALIDATION.requiredArray]
    })

    const requiredNotifMessage = computed(() => {
      return campaignSend.value ? [VALIDATION.required] : []
    })

    const routerTab = ref(null)
    const isPrincipal = ref(state?.accountType?.principal)
    const notif = ref(null)
    const showModal = ref(false)
    const filterForm = ref(null)
    const stepper = ref(null)
    const isDraft = ref(false)
    const isUpdateAllowed = ref(false)
    const campaignStep = ref(1)
    const campaignStepEnd = ref(6)
    const campaignType = ref(null)
    const campaignData = ref(null)
    const estimatedData = ref(0)
    const campaignStatus = ref(null)
    const campaignCode = ref(null)
    const campaignName = ref(null)
    const campaignDateFrom = ref(null)
    const campaignDateTo = ref(null)
    const campaignMechanics = ref(null)
    const campaignRetailer = ref(null)
    const campaignSegmentType = ref('all')
    const campaignSegment = ref(null)
    const campaignDiscountType = ref(null)
    const campaignSend = ref(true)
    const campaignMessage = ref(null)
    const campaignFile = ref(null)
    const uploadedFile = ref(null)
    const allowedFileTypes = ref([
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/pdf'
    ])
    const maxFileCount = ref(1)
    const maxFileSize = ref(5242880)

    const goToListsPage = () => {
      router.push(
        !state?.previousTab
          ? { name: 'campaign_lists' }
          : {
              name: 'campaign_lists_tab',
              params: { tab: state?.previousTab }
            }
      )
    }

    const onStepNext = () => {
      filterForm.value.validate().then(success => {
        if (success) {
          stepper.value.next()
        }
      })
    }

    const onStepPrevious = () => {
      stepper.value.previous()
    }

    const onStepSkip = () => {
      stepper.value.next()
    }

    const onToggleModal = () => {
      showModal.value = !showModal.value
    }

    const onSelectFromDate = data => {
      campaignDateFrom.value = data
      campaignDateTo.value = null
    }

    const onSelectToDate = data => {
      campaignDateTo.value = data
    }

    const toggleDraft = () => {
      resetForm()
      isDraft.value = true
    }

    const toggleCreate = () => {
      resetForm()
      isDraft.value = false
    }

    const toggleSave = () => {
      isDraft.value =
        campaignStatus.value ===
        campaign.find(item => item.name === 'draft')?.value
    }

    const resetForm = () => {
      filterForm.value.reset()
    }

    const checkFileType = files => {
      return files.filter(file => {
        return allowedFileTypes.value.includes(file.type)
      })
    }

    const onFileError = rejectedEntries => {
      if (rejectedEntries.length > 0) {
        let errMsg = 'File validation does not meet the requirements.'

        switch (rejectedEntries[0].failedPropValidation) {
          case 'duplicate': {
            errMsg = `File (${rejectedEntries[0].file.name}) already added.`
            break
          }

          case 'filters': {
            errMsg =
              'Invalid file type. Accepts excel, doc, and pdf files only.'
            break
          }

          case 'max-file-size': {
            errMsg = `File (${
              rejectedEntries[0].file.name
            }) exceeds ${Math.round(maxFileSize.value / 1000000)}MB.`
            break
          }

          case 'max-files': {
            errMsg = `You can upload up to ${maxFileCount.value} files only.`
            break
          }
        }

        showToast(errMsg, 'info')
      }
    }

    const onUpload = data => {
      if (data) {
        const payload = data
        postUpload('v1/campaign/upload', payload)
      }
    }

    const onSubmitCreate = type => {
      routerTab.value = type

      filterForm.value.validate().then(success => {
        if (isDraft.value) {
          if (!campaignCode.value) {
            campaignStep.value = 1
            return showToast('Campaign code is required', 'info')
          }

          if (!campaignName.value) {
            campaignStep.value = 1
            return showToast('Campaign name is required', 'info')
          }

          if (!campaignSegment.value && campaignSegment.value === 'segment') {
            campaignStep.value = 3
            return showToast('Customer segment is required', 'info')
          }

          if (!campaignDiscountType.value) {
            campaignStep.value = 4
            return showToast('Campaign type is required', 'info')
          }

          if (!campaignMessage.value || campaignMessage.value === '') {
            campaignStep.value = 5
            return showToast('Notification message is required', 'info')
          }
        }

        if (type === 'requested') {
          if (
            (!campaignSegment.value &&
              campaignSegmentType.value === 'segment') ||
            !campaignCode.value ||
            !campaignName.value ||
            !campaignMechanics.value ||
            !campaignDateFrom.value ||
            !campaignDateTo.value ||
            !uploadedFile.value ||
            campaignDateFrom.value === '' ||
            campaignDateTo.value === '' ||
            !campaignDiscountType.value ||
            !campaignMessage.value ||
            campaignMessage.value === ''
          ) {
            return showToast('All fields are required to submit.', 'info')
          }
        }

        if (success) {
          campaignType.value = type

          const payload = {
            campaign: omitBy(
              {
                customer_segment_id:
                  campaignSegmentType.value === 'all'
                    ? 0
                    : campaignSegment.value &&
                      campaignSegment.value?.map(item => item.value),
                retailer: state?.profile?.retailer_id,
                code: campaignCode.value,
                name: campaignName.value,
                mechanics: campaignMechanics.value,
                filename: uploadedFile.value,
                campaign_type: campaignDiscountType.value?.value,
                date_from:
                  campaignDateFrom.value && campaignDateFrom.value !== ''
                    ? DATE.toFriendlyDate(campaignDateFrom.value)
                    : null,
                date_to:
                  campaignDateTo.value && campaignDateTo.value !== ''
                    ? DATE.toFriendlyDate(campaignDateTo.value)
                    : null,
                notification_message:
                  campaignSend.value && campaignMessage.value
              },
              isNil
            )
          }

          if (isUpdatePage) {
            payload.campaign.status = campaignStatus.value
            postUpdate(`/v1/campaign/${campaignID}`, payload)
          } else {
            payload.campaign.status = campaign.filter(
              item => item.name === type
            )[0].value

            postCreate('/v1/campaign', payload)
          }
        }
      })
    }

    const getCustomerSegment = id => {
      getLists(`/v1/campaign/info/${id}`)
    }

    onBeforeMount(() => {
      if (isUpdatePage && campaignID) {
        getCustomerSegment(campaignID)
      }

      watch([dataLists, errorLists, loadingLists], () => {
        if (loadingLists.value) {
          campaignData.value = null
        } else {
          if (errorLists?.value) {
            showToast(
              errorLists?.value?.message ??
                'There was a problem fetching customer segment.',
              'danger'
            )
          } else if (dataLists?.value) {
            const data = dataLists?.value?.result

            isDraft.value =
              data?.status ===
              campaign.find(item => item.name === 'draft')?.value

            isUpdateAllowed.value =
              campaign
                ?.filter(item =>
                  ['draft', 'requested', 'rejected'].includes(item.name)
                )
                ?.map(item => item.value)
                ?.includes(data?.status) && isPrincipal.value

            if (isUpdateAllowed.value) {
              campaignData.value = {
                id: data?.id,
                campaignCode: data?.code,
                campaignName: data?.name
              }

              campaignStatus.value = data?.status
              campaignCode.value = data?.code
              campaignName.value = data?.name
              campaignDateFrom.value =
                data?.date_from && DATE.toFriendlyDate(data?.date_from)
              campaignDateTo.value =
                data?.date_to && DATE.toFriendlyDate(data?.date_to)
              campaignMechanics.value = data?.mechanics
              campaignRetailer.value = data?.retailer_id
              campaignFile.value = data?.filename && [
                {
                  name: data?.filename
                }
              ]
              uploadedFile.value = data?.filename
              campaignSegmentType.value =
                data?.customer_segment_id?.map(Number)[0] === 0
                  ? 'all'
                  : 'segment'
              campaignSegment.value =
                campaignSegmentType.value === 'segment'
                  ? data?.customer_segment_id
                      ?.map(Number)
                      .map((item, index) => ({
                        value: item,
                        label: data?.segment_name.split(',')[index]
                      }))
                  : null
              campaignDiscountType.value = {
                value: data?.campaign_type,
                label: data?.campaign_type_name
              }
              campaignSend.value = true
              campaignMessage.value = campaignSend.value
                ? data?.notification_message
                : null
            }
          }
        }
      })

      watch([dataCreate, errorCreate, loadingCreate], () => {
        if (loadingCreate.value) {
          if (!notif.value) {
            notif.value = $q.notify({
              type: 'ongoing',
              color: 'primary',
              message: 'Saving campaign...'
            })
          }
        } else {
          if (errorCreate?.value) {
            const errMsg = 'There was a problem saving campaign.'

            showToast(
              errorCreate?.value?.errors
                ? errorCreate?.value?.errors[0]?.msg
                : errorCreate?.value?.message ?? errMsg,
              'danger'
            )

            notif.value({
              type: 'negative',
              color: 'danger',
              message: errMsg
            })
          } else if (dataCreate?.value) {
            notif.value({
              type: 'positive',
              color: 'success',
              message: `Campaign successfully created.`
            })

            router.push(
              !state?.previousTab
                ? { name: 'campaign_lists' }
                : {
                    name: 'campaign_lists_tab',
                    params: { tab: routerTab.value }
                  }
            )
          }

          notif.value = null
        }
      })

      watch([dataUpdate, errorUpdate, loadingUpdate], () => {
        if (loadingUpdate.value) {
          if (!notif.value) {
            notif.value = $q.notify({
              type: 'ongoing',
              color: 'primary',
              message: 'Updating campaign...'
            })
          }
        } else {
          if (errorUpdate?.value) {
            const errMsg = 'There was a problem updating campaign.'

            showToast(
              errorUpdate?.value?.errors
                ? errorUpdate?.value?.errors[0]?.msg
                : errorUpdate?.value?.message ?? errMsg,
              'danger'
            )

            notif.value({
              type: 'negative',
              color: 'danger',
              message: errMsg
            })
          } else if (dataUpdate?.value) {
            notif.value({
              type: 'positive',
              color: 'success',
              message: `Campaign successfully updated.`
            })

            router.push(
              !state?.previousTab
                ? { name: 'campaign_lists' }
                : {
                    name: 'campaign_lists_tab',
                    params: { tab: state?.previousTab }
                  }
            )
          }

          notif.value = null
        }
      })

      watch([dataUpload, errorUpload, loadingUpload], () => {
        if (loadingUpload.value) {
          uploadedFile.value = null

          if (!notif.value) {
            notif.value = $q.notify({
              type: 'ongoing',
              color: 'primary',
              message: 'Attaching file...'
            })
          }
        } else {
          if (errorUpload?.value) {
            const errMsg = 'There was a problem attaching the file.'

            showToast(
              errorUpload?.value?.errors
                ? errorUpload?.value?.errors[0]?.msg
                : errorUpload?.value?.message ?? errMsg,
              'danger'
            )

            notif.value({
              type: 'negative',
              color: 'danger',
              message: errMsg
            })
          } else if (dataUpload?.value) {
            if (dataUpload?.value?.status) {
              uploadedFile.value = dataUpload?.value?.path

              notif.value({
                type: 'positive',
                color: 'success',
                message: `File successfully attached.`
              })
            }
          } else {
            uploadedFile.value = []
          }

          notif.value = null
        }
      })
    })

    return {
      HELP,
      FORMAT,
      VALIDATION,
      DATE,
      getIconUrl,
      isUpdatePage,
      isUpdateAllowed,
      isLoading,
      loadingLists,
      minDate,
      requiredField,
      requiredFieldArray,
      requiredNotifMessage,
      showModal,
      filterForm,
      stepper,
      campaignStep,
      campaignStepEnd,
      campaignData,
      estimatedData,
      campaignCode,
      campaignName,
      campaignDateFrom,
      campaignDateTo,
      campaignMechanics,
      campaignMessage,
      campaignRetailer,
      campaignSegmentType,
      campaignSegment,
      campaignDiscountType,
      campaignSend,
      campaignFile,
      uploadedFile,
      allowedFileTypes,
      maxFileCount,
      maxFileSize,
      goToListsPage,
      onStepNext,
      onStepPrevious,
      onStepSkip,
      onToggleModal,
      onSelectFromDate,
      onSelectToDate,
      onSubmitCreate,
      toggleDraft,
      toggleCreate,
      toggleSave,
      checkFileType,
      onFileError,
      onUpload
    }
  }
}
</script>

<style lang="scss" scoped>
ul {
  display: block;
  list-style: disc outside none;
  padding: 0 0 0 40px;
}

ol {
  display: block;
  list-style: decimal;
  padding: 0 0 0 40px;

  li {
    list-style-position: outside;
    padding-left: 10px;
  }
}

.labelContainer {
  @apply mt-2;

  .labelText {
    @apply text-darkgray font-semibold;
  }
}

.listHeader {
  @apply font-bold;
  max-width: 200px;
}

.stepHeight {
  min-height: 300px;
}
</style>
