<template>
  <CampaignCreate />
</template>

<script>
import CampaignCreate from '@/components/pages/campaign/CampaignCreate'

export default {
  components: {
    CampaignCreate
  }
}
</script>
