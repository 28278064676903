<template>
  <q-stepper-navigation>
    <div :class="containerClass">
      <Button
        class="q-ml-sm"
        variant="primary"
        label="Back"
        icon="arrow_back"
        :loading="loading"
        :disabled="loading"
        outline
        @click="onPrevious"
        @mousedown="onMouseDown"
        v-if="step > 1"
      />
      <div>
        <Button
          class="q-mr-sm"
          variant="primary"
          label="Skip"
          :loading="loading"
          :disabled="loading"
          @click="onSkip"
          v-if="step < stepEnd - 1"
        />
        <Button
          variant="primary"
          :label="step === stepEnd - 1 ? 'Review Campaign' : 'Continue'"
          icon-right="arrow_forward"
          :loading="loading"
          :disabled="loading"
          @click="onNext"
          @mousedown="onMouseDown"
          v-if="step < stepEnd"
        />
      </div>
    </div>
  </q-stepper-navigation>
</template>

<script>
import { computed } from 'vue'

import Button from '@/components/Button'

export default {
  name: 'CampaignStepperButton',
  components: {
    Button
  },
  props: {
    step: {
      type: Number,
      default: 1
    },
    stepEnd: {
      type: Number,
      default: 1
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const containerClass = computed(() => {
      return [
        'row items-center',
        {
          'justify-end': props.step === 1,
          'justify-between': props.step > 1
        }
      ]
    })

    const onNext = () => {
      emit('next')
    }

    const onPrevious = () => {
      emit('previous')
    }

    const onSkip = () => {
      emit('skip')
    }

    const onMouseDown = () => {
      emit('mouseDown')
    }

    return { containerClass, onNext, onPrevious, onSkip, onMouseDown }
  }
}
</script>
